<template>
  <v-btn
    @click="to ? $router.push(to) : $router.go(-1)"
    class="rounded mr-5"
    color="grey lighten-2"
    outlined
    small
    fab
  >
    <v-icon size="25" color="dark">mdi-chevron-left</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    to: {
      type: Object
    }
  }
}
</script>